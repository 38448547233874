.PaginateContainer {
  padding: 1rem;
  padding-left: 0;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 20px 16px;
  justify-content: center;
  list-style-type: none;
  list-style: none;
  color: #374146;
  margin: 0 auto;
}

.PaginateContainer a {
  text-decoration: none;
}

.PaginatePage {
  min-width: 20px;
  font-size: 16px;
  margin-left: 8px;
  margin-right: 8px;
}

.PaginatePage > a {
  width: 28px;
  height: 28px;
  text-decoration: none;
  font-size: 14px;
  border-radius: 50%;
}

.PaginatePage > a:hover {
  background-color: #263147;
  color: #fff;
}

.PaginateActive > a {
  background-color: #263147;
  color: #fff;
}

.PaginatePage:hover {
  cursor: pointer;
}

.PaginatePageLink {
  font-family: Roboto;
  width: 100%;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.PaginatePageLink:hover {
  /* color: #f57c00; */
}

.PaginatePreviousLink,
.PaginateNextLink {
  display: block;
  width: 26px;
  height: 26px;
  font-size: 16px;
}

.PaginatePreviousLink {
  background: url('/images/icon-counter-arrow-left.svg') no-repeat center;
  background-size: 8px 10px;
}
.PaginatePreviousLink.is-hidden {
  display: none;
}

.PaginateNextLink {
  background: url('/images/icon-counter-arrow-right.svg') no-repeat center;
  background-size: 8px 10px;
}
.PaginateNextLink.is-hidden {
  display: none;
}

.PaginatePreviousLink:hover {
  cursor: pointer;
}

.PaginateNextLink:hover {
  cursor: pointer;
}

.PaginateBreak {
  display: inline-flex;
}
